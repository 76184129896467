import axios from "axios";
import {logOutRedirectCall} from '../pages/Common/RedirectPathMange'
import Swal from 'sweetalert2'
import '../config';


const showMessage = (msg) => {
  Swal.fire({
    title: 'Cybersmarties',
    text: msg,
    confirmButtonText: 'Okay'
  })
}
const axiosClient = axios.create({
    baseURL: global.GLOBAL_CONSTANT.API_URL,
    headers: {
      'api-key':'n4lBiwpD7XqiqSYWQkczLCiGl5QBpPii',
      'accept-language':'en',
      'admin-type':'admin',
      'Content-Type': 'text/plain',
      'offline-access': 'true'
    }
  });

  axiosClient.interceptors.request.use(function (config) {
    
    config.headers['token']=localStorage.getItem("MAtoken")
    return config;
});

  axiosClient.interceptors.response.use(
    function (response) {
      if(response.data.code==0){
        showMessage(response.data.message)
      }
      return response;
    }, 
    function (error) {
      let res = error.response;
      if (res.status == 401) {
        logOutRedirectCall()
      }
      console.error("Looks like there was a problem. Status Code: " + res.status);
      return Promise.reject(error);
    }
  );

  export {
    axiosClient
  };
  
  