import React,{useState} from 'react'
import { useParams ,useNavigate} from 'react-router-dom'
import {changeReportStatusFreez} from '../../api/apiHandler'
import Select from 'react-select';

export default function ChangeReportStatus(props) {

    let { reportId } = useParams();

    let navigate = useNavigate(); 

    const statusData =  [
        { value: 'freeze', label: 'freeze' },
        { value: 'unfreeze', label: 'unfreeze' },
        { value: 'freezereporter', label: 'freezereporter' },
        // { value: 'dismiss', label: 'dismiss' }
        ] 

    const [selectedOptionStatus, setSelectedOptionStatus] = useState(statusData[0]);

    const handleStatusChange=(data)=>{
        setSelectedOptionStatus(data)
    }

    const clickSubmitButton = (e)=>{
        e.preventDefault();
        updateApiCall()
    }


    const updateApiCall = () =>{
        console.log("Change API update status")
        props.isLoader(true)


        changeReportStatusFreez({"report_id":reportId,"status":selectedOptionStatus.value}).then((resposnse)=>{
            props.isLoader(false)
            console.log(resposnse)
            if(resposnse.data.code==1){
                navigate(-1)
            }
        });
    } 

  return (
    <section className="content">

    <div className="container-fluid"> 
        <div className="row clearfix ">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card m-t-15">
                    <div className="header">
                        <h2>Change Report Status</h2>
                    </div>
                    <div className="body">
                    <form id="form_validation" method="" onSubmit={clickSubmitButton} action="#">
                                    <label htmlFor="name_en">Select Status</label>
                                    <div className="form-group">
                                    <Select
                                                    value={selectedOptionStatus}
                                                    onChange={handleStatusChange}
                                                    options={statusData}
                                                    name="selectStatus" 
                                                 />
                                      <input name="status" className='dropHideInput' value={ (selectedOptionStatus == null)? "" : ""+selectedOptionStatus.value  } id="status" type="text" required/>            
                                    </div>

                            <button className="btn btn-raised btn-primary waves-effect" type="submit">SUBMIT</button>
                        </form>                     
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
  )
}
