import React, { useState,useEffect } from 'react'
import { useParams ,useNavigate} from 'react-router-dom'
import {assignStaffToSupervisor,getAssignStaffToSupervisor,getAdminList} from '../../api/apiHandler'
import Select from 'react-select';


export default function AssignStaffSupervisior(props) {
    let id =  useParams()
    let navigate = useNavigate(); 

    const [selectedStaff, setSelectedStaff] = useState([]);
    const [stafflList, setStaffList] = useState([]);

    const handleStaffChange=(data)=>{
        console.log(data)
        setSelectedStaff(data)
    }

    const clickSubmitButton = (e)=>{
        e.preventDefault();
        assignSchool()
    }

    const assignSchool = () =>{
        console.log("Assign Staff Api Call")                
        props.isLoader(true)

        let staffIdList=[]

        if(selectedStaff!==null){
            if(selectedStaff.length>0){
                selectedStaff.forEach((item)=>{
                    staffIdList.push(item.value)                     
                })
                }
        }

        console.log(staffIdList)

        assignStaffToSupervisor({"supervisor_id":id.userId,"monitoring_staff_ids":staffIdList}).then((resposnse)=>{
            console.log(resposnse)
            props.isLoader(false)
            if(resposnse.data.code==1){
                navigate(-1)
            }
            
      })
    }


    const callGetAssignDetailsApi = (listData)=>{
        getAssignStaffToSupervisor({"supervisor_id":id.userId}).then((resposnse)=>{
            console.log(resposnse)
            props.isLoader(false)
            if(resposnse.data.code==1){
                let selectList=[]
                resposnse.data.data.forEach( (item) => {
                    listData.forEach((subItem)=>{
                        if(subItem.value===item.staff_id){
                            selectList.push(subItem)
                        }
                    })
                })

                setSelectedStaff(selectList)
            }else{
                if(listData.length>0){
                    setSelectedStaff(listData[0])
                }
            }
            })
    }

    useEffect(() => {
        let mounted = true;
        var adminId = localStorage.getItem("MAid",0);
        getAdminList({"page":"1","limit":"1000","sort_by":"id","order_by":"desc","role":"staff"}).then((resposnse)=>{
            if(resposnse.data.code==1){
                let list =[]
                resposnse.data.data.list.map((item, index) => {
                    console.log({label:item.name,value:item.id})
                    list.push({label:item.name,value:item.id})
                })
                setStaffList(list)
                callGetAssignDetailsApi(list)
                props.isLoader(false)
            }else{
                props.isLoader(true)
                navigate(-1)
            }
        });
        return () => mounted = false;
    }, []);

    const getSelectValue =()=>{
        let selectData=""
        if(selectedStaff!=null){
            if(selectedStaff.length>0){
                selectedStaff.forEach((item) =>{
                selectData=selectData+item.value
                })
            }
        }
        return selectData
    }

  return (
    <section className="content">

    <div className="container-fluid"> 
        <div className="row clearfix ">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card m-t-15">
                    <div className="header">
                        <h2>Manage Staff Assign</h2>
                    </div>
                    <div className="body">
                       <form id="form_validation" method="" onSubmit={clickSubmitButton} action="#">
                                <label htmlFor="name_en">Select Staff</label>
                                    <div className="form-group">
                                    <Select
                                                   isMulti
                                                   value={selectedStaff}
                                                    onChange={handleStaffChange}
                                                    options={stafflList}
                                                    name="selectStaffData" 
                                    />

                                    <input name="classAndDevision" className='dropHideInput' value={ (selectedStaff == null)? "" : getSelectValue()  } id="classAndDevision" type="text" required/>            

                                    </div>
                            <button className="btn btn-raised btn-primary waves-effect" type="submit">SUBMIT</button>
                        </form>                     
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
  )
}
