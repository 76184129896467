import React from 'react'
import { Link } from "react-router-dom";

export default function TopBar() {
  return (
    <nav className="navbar">
       <div className="col-12">    
            <div className="navbar-header">
                <a href={void(0)} className="bars"></a>
                <Link className="navbar-brand" to="/">Cybersmarties</Link>
            </div>
        </div>
    </nav>
  )
}
