import React from "react";
// import paring from '../../Utils/SzudzikPair'

export default function MonitoringComponents(props) {
    return (
        <div className="body">
            <div className="tab-content">
                <div
                    role="tabpanel"
                    className="tab-pane active"
                    id="official"
                    aria-expanded="true"
                >
                    <div class="alert bg-blue">Click to Save or to View</div>
                    {props.data &&
                        props.data.map((item, index) => {
                            return (
                                <li
                                    className="list-group-item"
                                    key={item.type_id}
                                >
                                    <p class="card-text">
                                        <div>
                                            {item.message}{" "}
                                            <strong>
                                                {item.type}-{item.created_at}
                                            </strong>
                                        </div>
                                        <button
                                            type="button"
                                            class="btn btn-raised btn-primary waves-effect m-1"
                                            onClick={() => {
                                                // console.log("viewClick", item, index);
                                                props.viewClick(index, item)
                                            }
                                            }
                                        >
                                            View
                                        </button>
                                        <button
                                            type="button"
                                            class={`btn ${item.in_capture > 0 ? 'btn-disabled' : 'btn-success'} btn-raised waves-effect m-1`}
                                            onClick={(e) => {
                                                if(e.target.disabled) {
                                                    // console.log('return');
                                                    return;
                                                }
                                                // console.log('Capture');
                                                props.saveClick(index, item);
                                            }
                                                
                                            }
                                            disabled={item.in_capture > 0}
                                        >
                                            {item.in_capture ? (
                                                <>Saved</>
                                            ) : (
                                                <>Save</>
                                            )}
                                        </button>
                                    </p>
                                </li>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}
