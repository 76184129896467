import React, { useState ,useEffect} from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import {getAdminList,deleteAdmin,changeAdminStatus,createChatRoom} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';
//Datatable Modules
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $ from 'jquery'; 
import Heading from '../../components/Heading/Heading';

export default function AdminListing(props) {
    const user = JSON.parse(localStorage.getItem('MAuserData'))

    const [myAdminList, setAdminList] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        getAdminList({"page":"1","limit":"1000","sort_by":"id","order_by":"desc"}).then((resposnse)=>{
            if(resposnse.data.code==1){
                setAdminList(resposnse.data.data.list);
            }else{
                setAdminList([]);
            }
        })
        return () => mounted = false;
    }, []);


    $(document).ready(function () {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                order: [[0, 'desc']],
                  pageLength: 10,
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ],
                      "bDestroy": true
            }
        );
    });

    let navigate = useNavigate(); 

    const redirectCreateAdmin = ()=>{
        let path ="/createMonitoringStaff"
        navigate(path)
    }

    const getRole = (roleData)=>{
        let role=""
        if(roleData==="supervisor"){
            role="Supervisor"
        }else{
            role="Staff"
        }

        return role
    }

    function deleteUserIdWise(userId){
        console.log("Call Delete"+userId)
        if(window.confirm("Are you sure you want to delete?")){
            props.isLoader(true)
            deleteAdmin({"monitoring_staff_id":userId}).then((resposnse)=>{
                console.log(resposnse);
                if(resposnse.data.code==1){
                    $('#mangeUser').DataTable().row("#userId"+userId).remove().draw();
                }else{
                }
                props.isLoader(false)
            });
        }
    }

    function createChatRoomApi(data){

        let role=data.role

        props.isLoader(true)
        createChatRoom({"sender_id":user.id,"sender_type":user.role,"receiver_type":role,"receiver_id":data.id,"school_id":"0"}).then((resposnse)=>{
            console.log(resposnse);
            if(resposnse.data.code==1){
                var item=resposnse.data.data;
                navigate("/chat",{state:{"chatId":item.chat_room_id,"rId":item.user_details.user_id,"rRole":role,"rName":item.user_details.display_user_name,"rStatus":item.user_details.chat_status}})
            }
            props.isLoader(false)
        });
    }

    const statusChangeListner = (position)=>{

       console.log(position) 

       props.isLoader(true)

       console.log("Call api change status")

       let apiData={}
       if(myAdminList[position].is_active===1){
            apiData={"monitoring_staff_id":myAdminList[position].id,"status":0}        
       }
       else{
            apiData={"monitoring_staff_id":myAdminList[position].id,"status":1}        
       }

       console.log(apiData)

       changeAdminStatus(apiData).then((resposnse)=>{
        console.log(resposnse)
        props.isLoader(false)
        if(resposnse.data.code==1){
            callAfterApiChangeStatus(position,true)
        }else{
            callAfterApiChangeStatus(position,false)
        }
        })    
    }

    const callAfterApiChangeStatus = (position,status)=>{

        if(status){

            if(myAdminList[position].is_active===1){
                myAdminList[position].is_active=0       
               }else{
                myAdminList[position].is_active=1
                }
        }

        setAdminList(myAdminList)

        manageCheckStatus(position)

    }

    const manageCheckStatus = (position)=>{
       if(myAdminList[position].is_active===1){
        $("#" + 'status'+position).attr("checked", true);        
       }else{
        $("#" + 'status'+position).attr("checked", false);        
        }
    }


    if (myAdminList==null) return null;
    props.isLoader(false)

    return (
    <section className="content home">
        <Heading title="Monitoring Staff Manage"/>
        <div className="container-fluid"> 
        <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                    <div className="header">
                    <button type="button" className="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right" onClick={redirectCreateAdmin}>Create</button>
                    </div>
                    <div className="body">
                        <table id="mangeUser" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Created Date</th>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Username</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>Language</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Created Date</th>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Username</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>Language</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                            <tbody>

                            { 
                            myAdminList && myAdminList.map((item,index)=>{
                                    return(
                                        <tr id={"userId"+item.id}>
                                            <td>{item.created_at}</td>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.username}</td>
                                            <td>{item.email}</td>
                                            <td>{item.country_code+" "+item.phone_number}</td>
                                            <td>{item.language}</td>
                                            <td>{getRole(item.role)}</td>
                                            <td>
                                                <div className="switch">
                                                    <label>
                                                        <input type="checkbox" id={"status"+index} onClick={()=>statusChangeListner(index)}/>
                                                        <span className="lever switch-col-blue" ></span>
                                                    </label>
                                                </div>  
                                                {manageCheckStatus(index)}
                                            </td>
                                            <td className="text-right">
                                            <div className='action-btn'>                        
                                            <a onClick={()=> {createChatRoomApi(item)}} className="btn btn-default col-green"><i className="zmdi zmdi-comment-list"></i></a>
                                            <Link to={`/monitoringStaffDetails/${item.id}`} className="btn btn-default col-green"><i className="zmdi zmdi-eye"></i></Link>
                                            <Link to={`/editMonitoringStaff/${item.id}`} className="btn btn-default col-green"><i className="zmdi zmdi-edit"></i></Link>
                                            <a onClick={()=> {deleteUserIdWise(item.id)}} className="btn btn-default col-red"><i className="zmdi zmdi-delete"></i></a>
                                            </div>    
                                            </td>
                                        </tr>

                                        )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    );
}
