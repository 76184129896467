import React, { useState ,useEffect} from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import {getPostListing} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';
//Datatable Modules
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $ from 'jquery'; 
import Heading from '../../components/Heading/Heading';

export default function PostListing(props) {

    const [myPostList, setPostList] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        getPostListing({"page":"1","limit":"1000","sort_by":"id","order_by":"desc"}).then((resposnse)=>{
            console.log(resposnse)
            if(resposnse.data.code==1){
                setPostList(resposnse.data.data.list);
            }else{
                setPostList([]);
            }
        })
        return () => mounted = false;
    }, []);


    $(document).ready(function () {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 10,
                  order: [[0, 'desc']],
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ],
                      "bDestroy": true
            }
        );
    });

    let navigate = useNavigate(); 

    const redirectCreateAdmin = ()=>{
        let path ="/createMonitoringStaff"
        navigate(path)
    }


    if (myPostList==null) return null;
    props.isLoader(false)

    return (
        
     <>  
     <section className="content home">
     <Heading title="Post"/>
        <div className="container-fluid"> 
        <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                    <div className="header">
                    </div>
                    <div className="body">
                    <table id="mangeUser" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Id</th>
                                    <th>Description</th>
                                    <th>Posted By</th>
                                    <th>Category</th>
                                    <th>School Name</th>
                                    <th>View / Comments</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Id</th>
                                    <th>Description</th>
                                    <th>Posted By</th>
                                    <th>Category</th>
                                    <th>School Name</th>
                                    <th>View / Comments</th>
                                 </tr>
                            </tfoot>
                            <tbody>

                            { 
                            myPostList && myPostList.map((item,index)=>{
                                    return(
                                        <tr id={"userId"+item.id}>
                                            <td>{item.created_at}</td>
                                            <td>{item.id}</td>
                                            <td>{item.description}</td>
                                            <td>{item.user}</td>
                                            <td>{item.category}</td>
                                            <td>{item.school}</td>
                                            <td className="text-right">
                                            <div className='action-btn'>                        
                                            <Link to={`/postDetails/${item.id}`} className="btn btn-default col-green"><i className="zmdi zmdi-eye"></i></Link>
                                            </div>    
                                            </td>

                                        </tr>
                                        )
                                })}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    </> 

    );
}


/*

<section class="content blog-page">
<Heading title="Post Listing"/>
<div class="container">
    <div class="row">

        { 
           myPostList && myPostList.map((item,index)=>{
                return(
                        <div class="col-lg-6 col-md-12 left-box">
                            <div class="card single-blog-post">
                                <div class="body">
                                    <h5 class="m-t-20">{item.description}</h5>
                                    <ul class="meta list-inline">
                                        <li><i class="zmdi zmdi-calendar col-lime"></i> {item.created_at} </li>
                                        <li><i class="zmdi zmdi-city-alt col-blue"></i> {item.school} </li>
                                    </ul>
                                    <br/>
                                    <ul class="meta list-inline m-t-20">
                                        <li><i class="zmdi zmdi-account col-blue"></i> Posted By: {item.user}</li>
                                        <li><i class="zmdi zmdi-label col-lime"></i> {item.category}</li>
                                        <li><Link to={`/postDetails/${item.id}`}><i class="zmdi zmdi-comment-text col-blue"></i>Comments</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div> 
                    )}) 
            }         
    </div>

    <div className="body">
                <table id="mangeUser" className="table table-bordered table-striped table-hover dataTable js-exportable">
                    <thead>
                        <tr>
                            <th>Created Date</th>
                            <th>Id</th>
                            <th>Description</th>
                            <th>Posted By</th>
                            <th>Category</th>
                            <th>School Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th>Created Date</th>
                            <th>Id</th>
                            <th>Description</th>
                            <th>Posted By</th>
                            <th>Category</th>
                            <th>School Name</th>
                            <th>Action</th>
                         </tr>
                    </tfoot>
                    <tbody>

                    { 
                    myPostList && myPostList.map((item,index)=>{
                            return(
                                <tr id={"userId"+item.id}>
                                    <td>{item.created_at}</td>
                                    <td>{item.id}</td>
                                    <td>{item.description}</td>
                                    <td>{item.user}</td>
                                    <td>{item.category}</td>
                                    <td>{item.school}</td>
                                    <td className="text-right">
                                    <div className='action-btn'>                        
                                    <Link to={`/postDetails/${item.id}`} className="btn btn-default col-green"><i className="zmdi zmdi-eye"></i></Link>
                                    </div>    
                                    </td>

                                </tr>
                                )
                        })}
                    </tbody>
                </table>
    </div>

</div>        
</section>
*/