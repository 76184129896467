import React from 'react'
import {homeRedirectCall} from './RedirectPathMange';

export default function RedirectBlankPageHome() {
  return (
    <div>
        {homeRedirectCall()}      
    </div>
  )
}
