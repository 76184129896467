import React, { useState ,useEffect} from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import {blockWordList} from '../../api/apiHandler'
//Datatable Modules
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $ from 'jquery'; 
import Heading from '../../components/Heading/Heading';

export default function BlockWordListing(props) {

    const [myBlockWordList, setBlockWordList] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        blockWordList({"page":"1","limit":"1000","sort_by":"id","order_by":"desc"}).then((resposnse)=>{
            if(resposnse.data.code===1){
                setBlockWordList(resposnse.data.data.list);
            }else{
                setBlockWordList([]);
            }
        })
        return () => mounted = false;
    }, []);


    $(document).ready(function () {
        $('#blockWords').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 10,
                  order: [[0, 'desc']],
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ],
                      "bDestroy": true
            }
        );
    });

    let navigate = useNavigate(); 
    
    const redirectCreateBlock = ()=>{
        let path ="/createBlockWord"
        navigate(path)
    }

    if (myBlockWordList==null) return null;
    props.isLoader(false)

    return (
    <section className="content home">
        <Heading title="Block Word"/>
        <div className="container-fluid"> 
        <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                    <div className="header">
                    <button type="button" className="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right" onClick={redirectCreateBlock}>Create</button>
                    </div>
                    <div className="body">
                        <table id="blockWords" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Id</th>
                                    <th>Word</th>
                                    <th>Created By Id</th>
                                    <th>Created By</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>Staus Active</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                <th>Created Date</th>
                                    <th>Id</th>
                                    <th>Word</th>
                                    <th>Created By Id</th>
                                    <th>Created By</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>Staus Active</th>
                                 </tr>
                            </tfoot>
                            <tbody>

                            { 
                            myBlockWordList && myBlockWordList.map((item,index)=>{
                                    return(
                                        <tr id={"blockWordId"+item.id}>
                                            <td>{item.created_at}</td>
                                            <td>{item.id}</td>
                                            <td>{item.word}</td>
                                            <td>{item.created_by_id}</td>
                                            <td>{item.created_by_name}</td>
                                            <td>{item.created_by}</td>
                                            <td>{item.status}</td>
                                            <td>{(item.is_active==1)?"Active":"Inactive"}</td>
                                        </tr>
                                        )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    );
}
