import React,{useState,useEffect} from 'react'
import MonitoringComponents from './MonitoringComponents'

import {monitoringList,ceptureMonitoringData} from '../../api/apiHandler'
import MonitoringDetailsComponents from './MonitoringDetailsComponents'
export default function MonitoringList(props) {

    const [dataList, setDataList] = useState([]);
    var listData=[];
    const [selectMonitoringData,setSelectMonitoringData]= useState(null);
    const [selectIndex,setSelectIndex]= useState(null);
    const [selectSaveData,setSelectSaveData]= useState(false);

    var requestDataTime="";
    let timer;
    useEffect(() => {
        const timeout = setTimeout(() => {
            var today = new Date();
            var hours = today.getUTCHours();
            var date = today.getUTCFullYear()+'-'+(today.getUTCMonth()+1)+'-'+today.getUTCDate();
            var time = ((hours > 0) ? (hours - 1) : 0)+ ":" + today.getUTCMinutes() + ":" + today.getUTCSeconds();
            var dateTime = date+' '+time;
            monitoringListAPICall(dateTime);
        }, 5000)
        return () => clearTimeout(timeout)
    })
    // const apiCallLoop = ()=>{
    //     timer=setTimeout(function(){
    //         monitoringListAPILoop()
    //     },5000)
    // }

    const monitoringListAPICall= (dateTimeData)=>{
        monitoringList({"last_datetime":dateTimeData,"school_ids":localStorage.getItem("MAschoolListId").replace("]","").replace("[","")}).then((resposnse)=>{
            if(resposnse.data.code===1){   
                listData=resposnse.data.data;
                // console.log('resposnse',listData);
                setDataList(resposnse.data.data);
                if(resposnse.data.data.length>0){
                    requestDataTime=resposnse.data.data[0].created_at;
                }else{
                    requestDataTime=dateTimeData;
                }
                // apiCallLoop();    
            }else{
                requestDataTime=dateTimeData;
                setDataList([]);
                // apiCallLoop()
                // console.log('API Call code not 1');
            }
        })
    }

    // const monitoringListAPILoop= ()=>{
    //     monitoringList({"last_datetime":requestDataTime,"school_ids":localStorage.getItem("MAschoolListId").replace("]","").replace("[","")}).then((resposnse)=>{
    //         var list=listData
    //         if(resposnse.data.code===1){
    //             var fetchData=resposnse.data.data;
    //             fetchData.reverse();
    //             fetchData.forEach(element => {
    //                 list.splice(0, 0, element)
    //             });
    //             setDataList([]);
    //             setTimeout(function(){
    //                     listData=list;
    //                     console.log(list);
    //                     setDataList(list);

    //             },0)

    //             if(resposnse.data.data.length>0){
    //                 requestDataTime=list[0].created_at;
    //             }
    //             apiCallLoop();    

    //         }else{
    //             apiCallLoop();
    //         }
    //     })
    // }
    // useEffect(() => {
    //     let mounted = true;
    //     var today = new Date();
    //     var date = today.getUTCFullYear()+'-'+(today.getUTCMonth()+1)+'-'+today.getUTCDate();
    //     var time = (today.getUTCHours()-2)+ ":" + today.getUTCMinutes() + ":" + today.getUTCSeconds();
    //     var dateTime = date+' '+time;
    //     monitoringListAPICall(dateTime);
    //     return () => {
    //         clearTimeout(timer);
    //       };
    // }, []);


    const buttonSave = (id,data)=>{
        setSelectMonitoringData(data)
        setSelectSaveData(false)
        let create_data = {
            "data_id":data.type_id,
            "data_type":data.type,
            "user_id":data.user_id,
            "school_id":data.school_id,
            "message":data.message,
            "school_name":data.school_name,
        }
        if (data.username) create_data.username = data.username;
        if (data.type === "Chat") {
            create_data.sender_id = data.user_id;
            create_data.sender_username = data.sender_username;
            create_data.receiver_id = data.receiver_id;
            create_data.receiver_username = data.receiver_username;
        }
        ceptureMonitoringData(create_data).then((resposnse)=>{
            if(resposnse.data.code===1){   
                setSelectSaveData(true)
                var today = new Date();
                var hours = today.getUTCHours();
                var date = today.getUTCFullYear()+'-'+(today.getUTCMonth()+1)+'-'+today.getUTCDate();
                var time = ((hours > 0) ? (hours - 1) : 0)+ ":" + today.getUTCMinutes() + ":" + today.getUTCSeconds();
                var dateTime = date+' '+time;
                monitoringListAPICall(dateTime);
                setDataList((prevData)=> {
                    let newData = [...prevData];
                    newData[id].in_capture = newData[id].in_capture + 1;
                    return newData;
                });
            }
        })
    }
    const buttonView = (id,data)=>{
        setSelectIndex(id);
        setSelectMonitoringData(data);
        setSelectSaveData(data.in_capture);
    }
  return (
    <section className="content home">   
    <div className="container-fluid"> 
        <div className="row clearfix">
            <div className="col-lg-6 col-md-6 col-sm-6 m-t-15">
                <div className="card">
                    <div className="header align-center">
                        <h2>Post, Comments And Message</h2>
                    </div>
                    <MonitoringComponents data={dataList} saveClick={buttonSave} viewClick={buttonView}/>
                </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6 m-t-15">
            <div className="card sticky-panel">
                   <MonitoringDetailsComponents data={selectMonitoringData} selectIndex={selectIndex} savedFlag={selectSaveData} saveClick={buttonSave}/>
            </div>
            </div>
        </div>
    </div>
    </section>
)
}
