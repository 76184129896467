import { axiosClient } from "./apiClient";


export function signInAdmin(data){
    return axiosClient.post('/api/admin/admin/user_login', data);
}

export function getDeshboardData(){
    return axiosClient.post('/api/admin/common/get_dashboard_counts'); 
}

export function getAdminList(data){
    return axiosClient.post('/api/admin/monitoring_staff/get_monitoring_staff_list',data); 
}

export function createAdmin(data){
    return axiosClient.post('/api/admin/monitoring_staff/add_monitoring_staff',data); 
}

export function getAdminDetails(data){
    return axiosClient.post('/api/admin/monitoring_staff/get_monitoring_staff_detail',data); 
}

export function editAdmin(data){
    return axiosClient.post('/api/admin/monitoring_staff/update_monitoring_staff',data); 
}

export function deleteAdmin(data){
    return axiosClient.post('/api/admin/monitoring_staff/delete_monitoring_staff',data); 
}

export function changeAdminStatus(data){
    return axiosClient.post('/api/admin/monitoring_staff/update_monitoring_staff_status',data);     
}

export function addAssingSchoolMonitoringStaff(data){
    return axiosClient.post('/api/admin/monitoring_staff/assign_school_to_monitoring_staff',data);     
}

export function getAssignedSchoolMonitoringStaff(data){
    return axiosClient.post('/api/admin/monitoring_staff/get_assigned_schools',data);     
}

export function getSchoolList(data){
    return axiosClient.post('/api/admin/school/get_school_list', JSON.stringify(data));
}

export function getPostListing(data){
    return axiosClient.post('/api/admin/post/get_post_list',data)
}


export function getAssignSchool(data){
    return axiosClient.post('/api/admin/admin/get_assigned_schools',data)
}

export function getPostDetails(data){
    return axiosClient.post('/api/admin/post/get_post_detail',data)
}

export function getCommonList(data){
    return axiosClient.post('/api/admin/comment/get_comment_list',data)
}

export function getReportList(data){
    return axiosClient.post('/api/admin/report/get_report_list',data)
}

export function changeReportStatus(data){
    return axiosClient.post('/api/admin/report/update_report_status',data)
}

export function deleteReport(data){
    return axiosClient.post('/api/admin/report/delete_report',data)
}

export function getReportDetails(data){
    return axiosClient.post('/api/admin/report/get_report_detail',data)
}

export function changeReportStatusFreez(data){
    return axiosClient.post('/api/admin/report/update_freeze_unfreeze',data)
}

export function addBlockWord(data){
    return axiosClient.post('/api/admin/blocked_word/add_block_word',data)
}

export function blockWordList(data){
    return axiosClient.post('/api/admin/blocked_word/get_blocked_word_list',data)
}

export function monitoringList(data){
    return axiosClient.post('/api/admin/monitoring/get_monitoring_list',data)
}

export function ceptureMonitoringData(data){
    return axiosClient.post('/api/admin/monitoring/add_capture_data',data)
}

export function captureMonitoringList(data){
    return axiosClient.post('/api/admin/monitoring/get_capture_data_list',data)
}

export function captureMonitoringDataStatus(data){
    return axiosClient.post('/api/admin/monitoring/update_capture_data_status',data)
}

export function captureMonitoringContentStatus(data){
    return axiosClient.post('/api/admin/monitoring/update_capture_content_status',data)
}

export function captureMonitoringUserStatus(data){
    return axiosClient.post('/api/admin/monitoring/update_capture_user_status',data)
}

export function createReportFromMonitoring(data){
    return axiosClient.post('/api/admin/monitoring/create_report',data)
}

export function createSendReportFromMonitoring(data){
    return axiosClient.post('/api/admin/monitoring/send_report',data)
}

export function assignStaffToSupervisor(data){
    return axiosClient.post('/api/admin/monitoring_staff/assign_monitoring_staff_to_supervisor',data)
}

export function getAssignStaffToSupervisor(data){
    return axiosClient.post('/api/admin/monitoring_staff/get_assigned_monitoring_staff',data)
}

export function getChatRoom(data){    
    return axiosClient.post('/api/v1/chat/get_chat_history',data)
}

export function searchData(data){
    return axiosClient.post('/api/admin/common/search_data',data)
}

export function getChatRoomList(data){    
    return axiosClient.post('/api/v1/chat/get_chat_room_list',data)
}

export function createChatRoom(data){    
    return axiosClient.post('/api/v1/chat/create_chat_room',data)
}

export function createReportSearchData(data){    
    return axiosClient.post('/api/admin/common/search_data_report',data)
}