import React from 'react'

export default function MonitoringDetailsComponents(props) {
  return (
    <div className="body">
    <div className="tab-content">
        <div role="tabpanel" className="tab-pane active" id="official" aria-expanded="true">
        { 
            props.data && 
                    <>
                    {console.log("Data My",props.data)}
                     <li className="list-group-item">{props.data.message}</li>
                     <li className="list-group-item"><b className="mr-2">Create : </b> {props.data.created_at}</li>      
                     <li className="list-group-item"><b className="mr-2">Type : </b> {props.data.type}</li>      
                     <li className="list-group-item"><b className="mr-2">School Id : </b> {props.data.school_id}</li>                         
                     <li className="list-group-item"><b className="mr-2">School Name : </b> {props.data?.school_name}</li>
                     {props.data.username ? <li className="list-group-item"><b className="mr-2">Username : </b> {props.data.username}</li> : ''}
                     {props.data.type=="Chat"?
                     <>
                      <li className="list-group-item"><b className="mr-2">Sender Id : </b> {props.data.user_id}</li>      
                      <li className="list-group-item"><b className="mr-2">Sender Username : </b> {props.data.sender_username}</li>
                      <li className="list-group-item"><b className="mr-2">Receiver Id : </b> {props.data.receiver_id}</li>      
                      <li className="list-group-item"><b className="mr-2">Receiver Username : </b> {props.data.receiver_username}</li>      
                      </>
                     :
                     <>
                      <li className="list-group-item"><b className="mr-2">User Id : </b> {props.data.user_id}</li>      
                     </>
                     }

                    {props.data.type=="Comment" ? 
                     <>
                     <li className="list-group-item"><b className="mr-2">Post Id : </b> {props.data.post_id}</li>     
                     <li className="list-group-item"><b className="mr-2">Post Content : </b> {props.data.post_content}</li>     
                     
                     </>
                     :
                     <>
                     </>}       

                     {
                       (props.savedFlag) ? 
                       <>
                        <li className="list-group-item"><div class="alert bg-green">Saved</div></li>      
                       </> 
                       : <> 
                       <li className="list-group-item">
                        <p class="card-text">
                            <button type="button" class="btn btn-raised btn-success waves-effect m-1" onClick={()=> { props.saveClick(props.selectIndex,props.data) }}>Save</button>
                        </p>
                      </li>
                      </> 
                     }
                    
                    </>                         
        }
        </div>
    </div>
</div>
  )
}


