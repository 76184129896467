import React, { useState ,useEffect} from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import {getPostDetails,getCommonList} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';
//Datatable Modules
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $ from 'jquery'; 
import Heading from '../../components/Heading/Heading';

export default function PostDetails(props) {

    let { userId } = useParams();
    let navigate = useNavigate(); 

    const [myPostDetails, setPostDetails] = useState(null)
    const [myCommentList, setCommentList] = useState()

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        console.log("Select USer Id"+userId)
        getPostDetails({"post_id":userId}).then((resposnse)=>{
            console.log(resposnse)
            if(resposnse.data.code==1){
                setPostDetails(resposnse.data.data);
            }else{
                props.isLoader(false)
                navigate(-1)
            }
        })
        getCommonList({"post_id":userId,"page":"1","limit":"1000","sort_by":"id","order_by":"desc"}).then((resposnse)=>{
            console.log(resposnse)
            if(resposnse.data.code==1){
                setCommentList(resposnse.data.data.list)
            }else{
                setCommentList([])
            }
        })
        return () => mounted = false;
    }, []);


    $(document).ready(function () {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 10,
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ],
                      "bDestroy": true
            }
        );
    });


    if (myPostDetails==null || myCommentList==null) return null;
        props.isLoader(false)

    return (
    <section className="content blog-page">
        <div className="container">
            <div className="row">
                {
                    <div className="col-lg-12 left-box m-t-20">
                    <div className="card single-blog-post">
                        <div className="body">
                            <h5 className="m-t-20">{myPostDetails.description}</h5>
                            <ul className="meta list-inline">
                                <li><i className="zmdi zmdi-calendar col-lime"></i> {myPostDetails.created_at} </li>
                                <li><i className="zmdi zmdi-city-alt col-blue"></i> {myPostDetails.school} </li>
                            </ul>
                            <br/>
                            <ul className="meta list-inline m-t-20">
                                <li><i className="zmdi zmdi-account col-blue"></i> Posted By: {myPostDetails.user}</li>
                                <li><i className="zmdi zmdi-label col-lime"></i> {myPostDetails.category}</li>
                            </ul>
                        </div>
                    </div>
                    </div>
                } 
            </div>

        <div className="card">
            <div className="body">
                <h4 className="m-b-20">COMMENTS</h4>
                {
                        (myCommentList.length<=0)? 
                        <h5>No comments found</h5>
                        :
                        <>
                        <ul className="comment-reply list-unstyled">
                            {
                            myCommentList.map((item,index)=>{
                                    return(
                                        <li className="row">
                                        <div className="text-box col-md-12 p-r-20 p-t-10 p-b-10">
                                            <h5 className="m-b-5">{item.from_user}</h5>
                                            <p>{item.comment}</p>
                                            <ul className="list-inline">
                                                <li>{item.created_at}</li>
                                            </ul>
                                            <hr></hr>
                                        </div>
                                    </li>
                                        )
                                })}
                        </ul>
                        </>
                    }

            </div>
        </div>

        </div>        
    </section>
    );
}
