import moment from "moment";
import React, { Component, useEffect, useState } from "react";
import Select from "react-select";
import { searchData, createReportSearchData } from "../../api/apiHandler";
import { ceptureMonitoringData } from '../../api/apiHandler'
import $ from "jquery";
import { Checkbox } from "pretty-checkbox-react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function Search(props) {
    const serachData = [
        { label: "All", value: "All" },
        { label: "Chat", value: "chat" },
        { label: "Comment", value: "comment" },
        { label: "Post", value: "post" },
    ];
    const [selectedSearch, setSelectedRole] = useState({
        label: "All",
        value: "All",
    });
    const [user_id, setUserId] = useState("");
    const [post_id, setPostID] = useState("");
    const [comment_id, setCommentID] = useState("");
    const [sender_id, setSenderID] = useState("");
    const [receiver_id, setReceiverID] = useState("");
    const [start_date, setStartDate] = useState("");
    const [end_date, setEndDate] = useState("");
    const [maxEndDate, setMaxEndDate] = useState(
        moment(new Date()).format("YYYY-MM-DD")
    );
    const [arrDataPost, setDataPost] = useState([]);
    const [arrDataComments, setDataComments] = useState([]);
    const [arrDataChat, setDataChat] = useState([]);
    const [search_text, setSearchText] = useState("");

    // console.log("rendered with userid", user_id);

    var navigate = useNavigate();
    var selectedData = [];

    const handleSubmit = () => {
        var limit = 500;

        if (selectedSearch.value === "All") {
            limit = 500;
        } else {
            limit = 1500;
        }

        let data = {
            user_id,
            post_id,
            comment_id,
            sender_id,
            receiver_id,
            start_date,
            end_date,
            search_data:
                selectedSearch.value == null ? "" : selectedSearch.value,
            search_text,
            limit: limit,
        };
        Object.keys(data).forEach((key) => {
            if (data[key] === "") {
                delete data[key];
            }
        });

        destroyTable();

        props.isLoader(true);
        searchData(data).then((response) => {
            if (response.data.code === 1) {
                setDataPost(response.data.data?.post);
                setDataComments(response.data.data?.comment);
                // console.log(response.data.data.chat);
                setDataChat(response.data.data?.chat);
                addDatable();
            }
            props.isLoader(false);
        });
    };

    useEffect(() => {
        handleSubmit();
    }, []);

    const addDatable = () => {
        $(document).ready(function () {
            $('#mangeReport').DataTable({
                pagingType: 'full_numbers',
                  pageLength: 50,
                  processing: true,
                  order: [[0, 'desc']],
                  dom: 'Bfrtip',
                      buttons: ['copy','csv','print'],
                      "bDestroy": true
            });
            /*$("#mangeReport").DataTable({
                bPaginate: false,
                order: [[0, "desc"]],
                processing: true,
                dom: "Bfrtip",
                buttons: ["copy", "csv", "print"],
                bDestroy: true,
            });*/
        });
    };

    const destroyTable = () => {
        var table = $("#mangeReport").DataTable();
        table.destroy();
    };

    const onChangeSelection = (e, id, type) => {
        console.log("Select=>", e.target.checked, id, type);

        if (e.target.checked) {
            selectedData.push({ type: type, id: id });
        } else {
            var filteredArray = selectedData.filter((e) => {
                if (e.type === type && e.id === id) {
                    return false;
                } else {
                    return true;
                }
            });

            selectedData = filteredArray;
        }

        console.log("Selected List Final==>", selectedData);
    };

    /*const showNotesDailog = () => {
        if (selectedData.length === 0) {
            Swal.fire({
                title: "Cybersmarties",
                text: "Please select first",
                confirmButtonText: "Okay",
            });
        } else {
            Swal.fire({
                inputLabel: "Notes",
                inputPlaceholder: "Type your notes here...",
                inputAttributes: {
                    "aria-label": "Type your notes here",
                },
                input: "textarea",
                confirmButtonText: "Submit",
                inputValidator: (value) => {
                    if (!value) {
                        return "You need to write something!";
                    }
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    createSelectDataRepor(result.value);
                }
            });
        }
    };*/

    const createSelectDataRepor = (notes) => {
        console.log("Call Create API==>", selectedData);
        createReportSearchData({
            report_data: selectedData,
            notes: notes,
        }).then((response) => {
            console.log("response", response);
            if (response.data.code === 1) {
                // navigate(0)
            }
            props.isLoader(false);
        });
    };

    const saveAction = (event, data, type)=>{
        var message = "";

        if (type === "Comment") {
            message = data.comment;
        } else if (type === "Post") {
            message = data.description;
        } else {
            message = data.message;
        }

        let create_data = {
            "data_id":data.id,
            "data_type":type,
            "user_id":data.user_id,
            "school_id":data.school_id,
            "message":message,
            "school_name":data.school_name,
        }

        if (data.username) create_data.username = data.username;
        if (type === "Chat") {
            create_data.sender_id = data.sender_id;
            create_data.user_id = data.sender_id;
            create_data.sender_username = data.sender_username;
            create_data.receiver_id = data.receiver_id;
            create_data.receiver_username = data.receiver_username;
        }

        ceptureMonitoringData(create_data).then((response)=>{
            if (response.data.code === 1) {
                alert(type + " is successfully captured!");
                event.target.disabled = true;
            }
        })
    }

    return (
        <section className="content home">
            <div className="container-fluid">
                <div className="card row m-l-10 m-t-10">
                    <div className="row clearfix">
                        <div className="col-lg-2 col-md-2 col-sm-2 m-t-15 m-l-20">
                            <div className="card row">
                                <label htmlFor="email">Select Type</label>
                                <Select
                                    isMulti={false}
                                    value={selectedSearch}
                                    onChange={(data) => {
                                        console.log(data);
                                        setSelectedRole(data);
                                        setUserId("");
                                        setPostID("");
                                        setCommentID("");
                                        setSenderID("");
                                        setReceiverID("");
                                        setSearchText("");
                                    }}
                                    options={serachData}
                                    name="selectSchoolData"
                                />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 m-t-15">
                            <label htmlFor="email">User ID</label>
                            <div className="form-line">
                                <input
                                    type="number"
                                    placeholder="User ID"
                                    className="form-control"
                                    id="userId"
                                    name="userId"
                                    value={user_id}
                                    onChange={(e) => setUserId(e.target.value) }
                                />
                            </div>
                        </div>
                        {selectedSearch?.label == "Post" && (
                            <div className="col-lg-2 col-md-2 col-sm-2 m-t-15">
                                <label htmlFor="email">Post ID</label>
                                <div className="form-line">
                                    <input
                                        type="number"
                                        placeholder="Post ID"
                                        className="form-control"
                                        id="postID"
                                        name="postID"
                                        value={post_id}
                                        onChange={(e) =>
                                            setPostID(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        {selectedSearch?.label == "Comment" && (
                            <div className="col-lg-2 col-md-2 col-sm-2 m-t-15">
                                <label htmlFor="email">Comment ID</label>
                                <div className="form-line">
                                    <input
                                        type="number"
                                        placeholder="Comment ID"
                                        className="form-control"
                                        id="commentId"
                                        name="commentId"
                                        value={comment_id}
                                        onChange={(e) =>
                                            setCommentID(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        )}

                        {selectedSearch?.label == "Chat" && (
                            <div className="col-lg-2 col-md-2 col-sm-2 m-t-15">
                                <label htmlFor="email">Sender ID</label>
                                <div className="form-line">
                                    <input
                                        type="number"
                                        placeholder="Sender ID"
                                        className="form-control"
                                        id="sDate"
                                        name="eDate"
                                        value={sender_id}
                                        onChange={(e) =>
                                            setSenderID(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        {selectedSearch?.label == "Chat" && (
                            <div className="col-lg-2 col-md-2 col-sm-2 m-t-15">
                                <label htmlFor="email">Receiver ID</label>
                                <div className="form-line">
                                    <input
                                        type="number"
                                        placeholder="Receiver ID"
                                        className="form-control"
                                        id="sDate"
                                        name="eDate"
                                        value={receiver_id}
                                        onChange={(e) =>
                                            setReceiverID(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        )}

                        <div className="col-lg-2 col-md-2 col-sm-2 m-t-15">
                            <label htmlFor="email">Start Date</label>
                            <div className="form-line">
                                <input
                                    type="datetime-local"
                                    placeholder="Start Date"
                                    className="form-control"
                                    id="sDate"
                                    name="eDate"
                                    onChange={(e) => {
                                        setStartDate(e.target.value);
                                    }}
                                    // onChange={stat}
                                />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 m-t-15">
                            <div className="form-line">
                                <label htmlFor="email">End Date</label>
                                <input
                                    type="datetime-local"
                                    placeholder="End Date"
                                    className="form-control"
                                    id="sDate"
                                    name="eDate"
                                    max={maxEndDate}
                                    onChange={(e) => {
                                        setEndDate(e.target.value);
                                    }}
                                    // min={1670928763}
                                    // value={formValues.email}
                                    // onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 m-t-15">
                            <label htmlFor="email">Search Text</label>
                            <div className="form-line">
                                <input
                                    type="text"
                                    placeholder="Search Text"
                                    className="form-control"
                                    id="searchText"
                                    name="searchText"
                                    value={search_text}
                                    onChange={(e) => setSearchText(e.target.value) }
                                />
                            </div>
                        </div>

                        <button
                            className="btn btn-raised btn-primary waves-effect"
                            onClick={handleSubmit}
                            style={{
                                alignSelf: "center",
                                justifyContent: "center",
                            }}
                        >
                            Search
                        </button>

                        <input type="checkbox" />
                    </div>
                </div>
                <div className="row clearfix">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="card">
                            <div className="body">
                                <table
                                    id="mangeReport"
                                    className="table table-bordered table-striped table-hover dataTable js-exportable"
                                >
                                    <thead>
                                        <tr>
                                            <th>Created Date</th>
                                            <th>Id</th>
                                            <th>School Name</th>
                                            <th>Data</th>
                                            <th>User Id</th>
                                            <th>Type</th>
                                            <th>Select</th>
                                            <th>Save</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>Created Date</th>
                                            <th>Id</th>
                                            <th>School Name</th>
                                            <th>Data</th>
                                            <th>User Id</th>
                                            <th>Type</th>
                                            <th>Select</th>
                                            <th>Save</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {arrDataPost.map((item, index) => {
                                            return (
                                                <tr id={"reportId" + item.id}>
                                                    <td>{item?.created_at}</td>
                                                    <td>{item?.id}</td>
                                                    <td>{item?.school}</td>
                                                    <td>{item?.description}</td>
                                                    <td>{item?.user_id}</td>
                                                    <td>Post</td>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            className="checkBoxMy"
                                                            onChange={(e) =>
                                                                onChangeSelection(
                                                                    e,
                                                                    item.id,
                                                                    "post"
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                    <td><button className="btn btn-raised btn-primary waves-effect" onClick={(e)=>saveAction(e, item, "Post")} style={{alignSelf: "center",justifyContent: "center",}}>Save</button></td>
                                                </tr>
                                            );
                                        })}
                                        {arrDataComments.map((item, index) => {
                                            return (
                                                <tr id={"reportId" + item.id}>
                                                    <td>{item?.created_at}</td>
                                                    <td>{item?.comment_id}</td>
                                                    <td>{item?.school_name}</td>
                                                    <td>{item?.comment}</td>
                                                    <td>
                                                        {item?.from_user_id}
                                                    </td>
                                                    <td>Comment</td>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            className="checkBoxMy"
                                                            onChange={(e) =>
                                                                onChangeSelection(
                                                                    e,
                                                                    item.comment_id,
                                                                    "comment"
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                    <td><button className="btn btn-raised btn-primary waves-effect" onClick={(e)=>saveAction(e, item, "Comment")} style={{alignSelf: "center",justifyContent: "center",}}>Save</button></td>
                                                </tr>
                                            );
                                        })}
                                        {arrDataChat.map((subitem, index) => {
                                            return (
                                                <tr
                                                    id={"reportId" + subitem.id}
                                                >
                                                    <td>
                                                        {subitem?.created_at}
                                                    </td>
                                                    <td>
                                                        {subitem?.id +
                                                            ", Room ID - " +
                                                            subitem?.chat_room_id}
                                                    </td>
                                                    <td>
                                                        {subitem?.school_name ||
                                                            subitem
                                                                ?.sender_detail
                                                                ?.school_name}
                                                    </td>
                                                    <td>{subitem?.message}</td>
                                                    <td>
                                                        {"Sender - " +
                                                            subitem?.sender_id +
                                                            "\n Receiver - " +
                                                            subitem?.receiver_id +
                                                            ""}
                                                    </td>
                                                    <td>Chat</td>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            className="checkBoxMy"
                                                            onChange={(e) =>
                                                                onChangeSelection(
                                                                    e,
                                                                    subitem.id,
                                                                    "chat"
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                    <td><button className="btn btn-raised btn-primary waves-effect" onClick={(e)=>saveAction(e, subitem, "Chat")} style={{alignSelf: "center",justifyContent: "center",}}>Save</button></td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
