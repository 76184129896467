
const S3_BUCKET ='cybersmarties-prod';
const REGION ='eu-west-1';
const ACCESS_KEY ='AKIA4R7PQJRBQ6VL42UW';
const SECRET_ACCESS_KEY ='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';

export function uploadMonitoringAdminImages(){
      return {
        bucketName: S3_BUCKET,
        dirName:"cybersmarties/monitoring_staff",
        region: REGION,
        accessKeyId: ACCESS_KEY,
        secretAccessKey: SECRET_ACCESS_KEY,
       }
    }

    export function getMainBucketPath(){
      return "https://cybersmarties-prod.s3.eu-west-1.amazonaws.com/cybersmarties/"
    }
    
    export function getMonitoringAdminPath(){
      return "monitoring_staff/"
    }