import React, { useState ,useEffect} from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import {getChatRoomList} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';
//Datatable Modules
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $ from 'jquery'; 
import Heading from '../../components/Heading/Heading';

export default function RecentChat(props) {
    const user = JSON.parse(localStorage.getItem('MAuserData'))

    const [chatPostListing, setChatPostListing] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        getChatRoomList({"user_id":user.id,"user_type":user.role}).then((resposnse)=>{
            if(resposnse.data.code===1){
                // console.log(resposnse.data.data);
                setChatPostListing(resposnse.data.data);
                props.isLoader(false)
            }else{
                setChatPostListing([]);
            }
        })
        return () => mounted = false;
    }, []);


    $(document).ready(function () {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 10,
                  order: [[0, 'desc']],
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ],
                      "bDestroy": true
            }
        );
    });

    let navigate = useNavigate(); 

    const redirectCreateAdmin = ()=>{
        let path ="/createMonitoringStaff"
        navigate(path)
    }


    if (chatPostListing==null) return null;
    props.isLoader(false)

    return (
        
     <>  
     <section className="content home">
     <Heading title="Recent Chat"/>
        <div className="container-fluid"> 
        <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                    <div className="header">
                    </div>
                    <div className="body">
                    <table id="mangeUser" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Chat ID</th>
                                    <th>Reciver Name</th>
                                    <th>Reciver Type</th>
                                    <th>Last Message</th>
                                    <th>Status</th>
                                    <th>Message Date</th>
                                    <th>View</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Chat ID</th>
                                    <th>Reciver Name</th>
                                    <th>Reciver Type</th>
                                    <th>Last Message</th>
                                    <th>Status</th>
                                    <th>Message Date</th>
                                    <th>View</th>
                                 </tr>
                            </tfoot>
                            <tbody>

                            { 
                            chatPostListing && chatPostListing.map((item,index)=>{
                                // console.log("Data","Load Chat")
                                    return(
                                        <tr id={"chatRoomId"+item.chat_room_id}>
                                            <td>{item.chat_room_id}</td>
                                            <td>{item.user_details.display_user_name}</td>
                                            <td>{item.user_details.user_role}</td>
                                            <td>{(item.last_message_details===null) ?
                                            ""
                                              :
                                             item.last_message_details.message }</td>
                                            <td>{
                                            
                                            (item.user_details.chat_status === "online") ?
                                            <>
                                            <div class="about">
                                                <div class="status"> <i class="zmdi zmdi-circle online"></i> online </div>
                                            </div>
                                            </>
                                            :
                                            <>
                                            <div class="about">
                                                <div class="status"> <i class="zmdi zmdi-circle offline"></i> offline </div>
                                            </div>
                                            </>                                        
                                            }
                                            
                                            </td>
                                            <td>
                                                {
                                                    (item.last_message_details===null) ?
                                                     ""
                                                     :
                                                     item.last_message_details.created_at
                                                }
                                            </td>
                                            <td className="text-right">
                                            <Link to={`/chat`} state={{"chatId":item.chat_room_id,"rId":item.user_details.user_id,"rRole":item.user_details.user_role,"rName":item.user_details.display_user_name,"rStatus":item.user_details.chat_status}} className="btn btn-default col-green"><i className="zmdi zmdi-comment-list"></i></Link>
                                            </td>

                                        </tr>
                                        )
                                })}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    </> 

    );
}